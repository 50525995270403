import * as styles from './input.module.css';

export const Input = ( { value,onChange,isRequired, ...props} ) => {
    return <>
        <input
        {...props}
        className={ styles[ 'input' ] } 
        value={ value }
        onChange={ onChange }
        id={props.name}
        />
        {isRequired && <span className={styles['input-required']}>Required</span>}
    </>
}

