.zip-form-item {
    display: flex;
    flex-direction: column;
    color: #999999;
    padding-bottom: 20px;
}
.zip-page-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.zip-page-wrapper .zip-submit-wrapper {
    height: unset;
    display: flex;
    flex-direction: column;
    justify-content: end;
}
.err-wrapper-zip-code {
    margin-top: 50px !important;
}

@media only screen and (max-device-width: 480px) {
    .zip-page-wrapper .zip-submit-wrapper {
        height: 100%;
    }
}