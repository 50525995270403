.input {
    background: transparent;
    outline: none;
    font-size: 30px;
    width:100%;
    border: none;
    border-bottom: 2px solid #b9b9b9;
    font-family: 'Roboto',
    sans-serif;
    font-weight: 300;
    }

    @media only screen and (max-device-width: 480px) {
        .input {
            font-size: 25px;
        }
    }

.input:focus {
    border-color: #2fadab;
}

.input::placeholder {
    color: #999;
}

.input-required{
    margin-top: 5px;
    font-size: 15px;
    color: #505050;
    font-weight: 300;
}