import { useEffect, useState } from 'react';
import { GreenBtnView } from './GreenBtnView/GreenBtnView';
import { SmartmeterView } from './SmartmeterView/SmartMeterView';
import { CaptureView } from './CaptureView/CaptureView';

export const Utility = ({ callback, session }) => {
    const [utilityObject, setUtilityObject] = useState({});

    useEffect(() => {
        if (session.utility !== 'not-found') {
            (async () => {
                const utilityObjectData = await fetchUtilityByCode(session.utility);
                setUtilityObject(utilityObjectData);
            })();
        }
    }, [session.utility]);

    const nextHandler = async data => {
        callback(data);
    };

    const renderContent = {
        greenbutton: <GreenBtnView utility={utilityObject} redirectUrl={session.utilityConfigureUrl} />,
        smartmeter: <SmartmeterView utility={utilityObject} onSubmit={nextHandler} email={session.email} />,
        capture: <CaptureView utility={utilityObject} onSubmit={nextHandler} email={session.email} />,
    };

    return (
        <div className="flex-center utility-container" style={{ height: '100%' }}>
            {session.utility === 'not-found' && <CaptureView utility={null} onSubmit={nextHandler} email={session.email} />}
            {utilityObject && renderContent[utilityObject?.config?.type]}
        </div>
    );
};

export const fetchUtilityByCode = async code => {
    const response = await fetch(`/api/v1/utilities/${code}`, { method: 'get', headers: { 'Content-Type': 'application/json' } });
    const respBody = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(response.error.message);
};
