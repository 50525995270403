import { useEffect } from 'react';
import { useGA4React } from 'ga-4-react';
import { GA_EVENT_NAMES, VIEWS } from './helpers/constants';

const useAnalytics = (view, session) => {
    const ga4 = useGA4React();

    useEffect(() => {
        if (!ga4 || !session || !view) return;
        if (!Object.values(VIEWS).includes(view)) return;

        if (view === VIEWS.fail) {
            ga4.gtag('event', GA_EVENT_NAMES.integrationFail, session);
            return;
        }

        if (view === VIEWS.error) {
            ga4.gtag('event', GA_EVENT_NAMES.unexpectedError, session);
            return;
        }

        if (view === VIEWS.success) {
            ga4.gtag('event', GA_EVENT_NAMES.integrationSuccess, session);
            return;
        }

        ga4.gtag('event', GA_EVENT_NAMES.changeView, {
            page: view,
            ...session,
        });
    }, [ga4, view, session]);
};

export default useAnalytics;
