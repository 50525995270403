.title-description {
    padding-bottom: 50px;
}

.title-description .page-title {
    color: #505050;
    font-size: 30px;
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 0;
    text-align: center;
}