.layout-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.layout-header {
    padding: 0 10px;
    min-height: 3.25rem;
    border-bottom: 1px solid #b9b9b9;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.app-navigation {
    position: absolute;
    left: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.app-navigation img {
    margin-right: 5px;
}

.app-title {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: #252525;
}

.layout-content {
    padding: 24px 5px;
    flex: 1 0 auto;
    color: #505050;
    font-size: 24px;
    background: #f2f2f2;
}

.container {
    max-width: 600px;
    height: 100%;
    margin: 0 auto;
}
