import { useEffect, useState } from 'react';
import { Button } from '../common/button/button';
import { TitleDescription } from '../common/title-description/title-description';
import iconLocation from '../../images/icon-location.svg';
import { Location } from './location';
import * as styles from './zip.module.css';
import { Input } from '../common/input/input';

export const Zip = ({ callback, session }) => {
    const [zipCode, setZipCode] = useState('');

    useEffect(() => {
        if (session.zip) {
            setZipCode(session.zip);
        }
    }, []);

    const handleChange = e => setZipCode(e.target.value);

    const nextHandler = async e => {
        e.preventDefault();
        callback({ zip: zipCode });
    };

    return (
        <div className={styles['zip-page-wrapper']}>
            <div className="content-wrapper">
                <form onSubmit={nextHandler} id="zip-code-form">
                    <div className={styles['zip-form-item']}>
                        <TitleDescription img={iconLocation} title="My Location" />
                        <Input value={zipCode} onChange={handleChange} placeholder="Zipcode" isRequired={true} type="tel" />
                    </div>
                </form>
            </div>
            {session.clientId !== 'sst' && <Location setZipCode={setZipCode} />}
            <div className={`${styles['zip-submit-wrapper']} content-wrapper`}>
                <div className="flex-center">
                    <Button type="submit" form="zip-code-form" disabled={!zipCode} label="Next" onClick={undefined} />
                </div>
            </div>
        </div>
    );
};
