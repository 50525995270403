import { useEffect } from 'react';
import { fetchSession, GuestSessionError, initSession, updateSession } from './session';
import { useSession } from './useSession';
import { Zip } from './components/zip/zip';
import { Utility } from './components/utility/utility';
import { Error } from './components/error/error';
import { UtilityList } from './components/utility-list/utility-list';
import { UtilityConfigSuccess } from './components/utility-config-success/utility-config-success';
import { UtilityConfigFail } from './components/utility-config-fail/utility-config-fail';
import chevronLeft from './images/chevron-left.svg';
import * as styles from './app.module.css';
import useAnalytics from './useAnalytics';

const initOrRestoreSession = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const isSessionInitRequired = queryParams.has('sign') && queryParams.has('clientId');
    const formattedQueryParams = Object.fromEntries(queryParams);

    const initSessionAction = async () => {
        sessionData = await initSession(formattedQueryParams);
        window.history.replaceState({}, '', `${window.location.protocol}//${window.location.host}${window.location.pathname}`);
    };

    let sessionData = {};

    if (isSessionInitRequired) {
        await initSessionAction();
    } else {
        try {
            sessionData = await fetchSession();
        } catch (error) {
            if (error instanceof GuestSessionError) {
                await initSessionAction();
            } else {
                sessionData = {
                    error: 'Cannot fetch session!',
                };
            }
        }
    }

    return sessionData;
};

export const App = () => {
    const [view, session, setSession, setView] = useSession();

    useEffect(() => {
        (async () => {
            const sessionData = await initOrRestoreSession();
            setSession(sessionData);
        })();
    }, []);

    useAnalytics(view, session);

    const handleUpdateSession = async data => {
        const newSession = await updateSession(data);
        setSession(newSession);
    };

    const zipCallback = async ({ zip }) => {
        handleUpdateSession({ ...session, zip, utility: null });
    };

    const utilityListCallback = async ({ utility }) => {
        handleUpdateSession({ ...session, utility: utility.code });
    };

    const utilityCallback = async data => {
        handleUpdateSession({ ...session, ...data });
    };

    const errorCallback = data => {
        console.info('errorCallback', data);
    };

    const renderContent = () => {
        switch (view) {
            case 'zip':
                // this view is to ask a user zip code
                return {
                    view: <Zip callback={zipCallback} session={session} />,
                };
            case 'utility-list':
                // this view is to display a list of utilities that ara available for a user entered zip code
                return {
                    view: <UtilityList callback={utilityListCallback} zip={session.zip} />,
                    back: () => setView('zip'),
                };
            case 'utility':
                // this view is to show different utility configuration forms
                return {
                    view: <Utility callback={utilityCallback} session={session} />,
                    back: () => setView('utility-list'),
                };
            case 'success':
                // this view is to show that a utility configuration is successful - from this view user should be redirected (when a button pressed) back to clients site
                return {
                    view: <UtilityConfigSuccess session={session} />,
                };
            case 'fail':
                // this view is to show that a utility configuration is failed - from this view a user should have a way to retry - return back
                return {
                    view: <UtilityConfigFail session={session} />,
                    back: () => setView('utility'),
                };
            case 'error':
                // this view is used to display some unexpected errors
                return {
                    view: <Error callback={errorCallback} message={session.error} />,
                };
            default:
                // loading view, while we initializing or fetching session from backend - display some nice loader
                return {
                    view: 'Loading...',
                    title: '',
                };
        }
    };

    return (
        <div className={styles['layout-container']}>
            <div className={styles['layout-header']}>
                {renderContent()?.back && (
                    <div className={styles['app-navigation']} onClick={renderContent().back}>
                        <img src={chevronLeft} alt="arrow-left" />
                    </div>
                )}
                <h2 className={styles['app-title']}>Enersponse Connect</h2>
            </div>
            <div className={styles['layout-content']}>
                <div className={styles['container']}>{renderContent().view}</div>
            </div>
        </div>
    );
};
