import { Accordion } from '../../common/accordion/accordion';
import { TitleDescription } from '../../common/title-description/title-description';
import iconQuestions from '../../../images/icon-faq.svg';
import enersponseLogo from '../../../images/enersponse.webp';
import * as styles from './GreenBtnView.css';

const content = [
    {
        title: 'Why do I need to connect to my utility account?',
        text: `We are requesting a connection to your utility provider so that we can read your meter information and receive periodic energy data.
        We use this data to enroll you into an energy saving program, as well display the energy data within this app.`,
    },
    {
        title: 'What if I don`t have an online utility account?',
        text: 'This is common if you are in an apartment or shared premises.It`s ok as we will still send notifications when the grid is stressed to help reduce usage for a short period.',
    },
    {
        title: 'What if I don`t know my utility login information?',
        text: 'You can always skip this step and come back to it later.',
    },
];

export const GreenBtnView = ({ utility, redirectUrl }) => (
    <div className="content-wrapper">
        <TitleDescription img={enersponseLogo} title="Share my Data" />
        <div>
            <p style={{ marginTop: 0 }}>You will now be redirected to a {utility.name} managed website to log in.</p>
            <p>Make sure you have your login info ready so you can access your account.</p>
            <div className="flex-center">
                <a className="redirect-btn" href={redirectUrl}>
                    Start
                </a>
            </div>
        </div>
        <div style={{ marginTop: '50px' }}>
            <div className="content-wrapper" style={{ paddingBottom: 0, background: '#f2f2f2' }}>
                <div className="subtitle">
                    <img src={iconQuestions} />
                    <span>Frequently Asked Questions</span>
                </div>
            </div>
            <Accordion>
                {content.map((item, i) => (
                    <div className={`content-wrapper accordion-item-container`} key={i}>
                        <Accordion.Toggle eventKey={`question-${i}`}>
                            <span style={{ fontWeight: 'bold' }}>{item.title}</span>
                        </Accordion.Toggle>
                        <Accordion.Content eventKey={`question-${i}`}>
                            <div style={{ marginTop: '30px', letterSpacing: '0.5px' }}>{item.text}</div>
                        </Accordion.Content>
                    </div>
                ))}
            </Accordion>
        </div>
    </div>
);
