import { useEffect, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import iconPrecision from '../../images/icon-precision.svg';
import { ErrorMsg } from '../common/errorMsg/errorMsg';
import { Button } from '../common/button/button';
import config from '../../config';
import * as styles from './zip.module.css';

export const Location = ({ setZipCode }) => {
    const [isLocationAvailable, setIsLocationAvailable] = useState(false);
    const [notification, setNotification] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => setIsLocationAvailable(!!navigator?.geolocation), []);

    async function findZip() {
        const { state } = await navigator.permissions.query({ name: 'geolocation' });
        if (state === 'prompt' || state === 'granted') {
            setNotification(false);
            setLoading(true);
            navigator.geolocation.getCurrentPosition(positionFound, positionNotFound, {
                enableHighAccuracy: true,
                maximumAge: 30000,
                timeout: 27000,
            });
        } else if (state === 'denied') {
            error && setError(null);
            setNotification(true);
        }
    }

    async function positionFound(position) {
        const zip = await getZipByPosition(position);
        if (!zip) {
            setError('Cannot get zip code!');
            return;
        }
        setLoading(false);
        setZipCode(zip);
    }

    function positionNotFound(err) {
        setError(err.message);
        setLoading(false);
    }

    return (
        isLocationAvailable && (
            <div className="content-wrapper" style={{ marginTop: '3px' }}>
                <p style={{ textAlign: 'center' }}>Or, Set your location automatically</p>
                <div className="flex-center">
                    <Button
                        label={
                            <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {loading ? (
                                    <ClipLoader size={15} color="#2fadab" css={{ marginRight: '5px', verticalAlign: 'middle' }} />
                                ) : (
                                    <img src={iconPrecision} alt="location icon" style={{ marginRight: '8px' }} />
                                )}
                                Get my location
                            </span>
                        }
                        btnType="outline"
                        onClick={findZip}
                    />
                </div>
                {notification && (
                    <p style={{ textAlign: 'center', fontSize: '12px', color: '#2fadab' }}>
                        Please reactivate the location sharing in your browser's settings.{' '}
                    </p>
                )}
                {error && (
                    <ErrorMsg
                        errWrapperClassName={styles['err-wrapper-zip-code']}
                        msg={
                            <>
                                <p style={{ marginBottom: 0 }}>{error}.</p>
                                <p style={{ marginTop: 0 }}>Please try again or manually enter your Zipcode.</p>
                            </>
                        }
                    />
                )}
            </div>
        )
    );
};

const getZipByPosition = async position => {
    try {
        const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&result_type=postal_code&key=${config.GOOGLE_API}`,
            { mode: 'cors' }
        );
        if (!response.ok) return null;
        const respBody = await response.json();
        if (Object.keys(respBody).length === 0) return null;

        const postalComponent = respBody.results[0].address_components;
        let zip = null;
        for (let component of postalComponent) {
            /** we expect that the postal_code type is always in first position */
            const firstType = component.types[0];
            if (firstType === 'postal_code') {
                zip = component?.long_name || null;
                break;
            }
        }
        return zip;
    } catch (err) {
        return null;
    }
};
