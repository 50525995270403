import { useState } from 'react';

const prepareInitialState = params => {
    if (params.success) {
        return 'success';
    }
    if (params.success === false) {
        return 'fail';
    }

    if (params.error) {
        return 'error';
    }

    if (params.zip && params.utility) {
        return 'utility';
    }
    if (params.zip) {
        return 'utility-list';
    }

    return 'zip';
};

export function useSession() {
    const [view, setView] = useState('waiting');
    const [session, setSession] = useState({});

    const setSessionWrapper = newSession => {
        console.info(`[useSession] session update`, newSession);
        const newView = prepareInitialState(newSession);
        console.info(`[useSession] newView`, newView);
        setSession({ ...session, ...newSession });
        console.info(`[useSession] session`, { ...session, ...newSession });
        setView(newView);
    };

    return [view, session, setSessionWrapper, setView];
}
