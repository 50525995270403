.button {
    cursor: pointer;
    padding: 15px;
    border-radius: 15px;
    width: 100%;
    max-width: 380px;
    border: transparent;
    font-size: 24px;
}

.button-primary {
    color: #fff;
    background-color: #2fadab;
}

.button-primary:hover {
    background-color: #23b9b7;
    cursor: pointer;
}

.button-outline {
    background: transparent;
    color: #2fadab;
    border: 2px solid #2fadab;
}

.button-disabled {
    background: transparent;
    color: #9f9f9f;
    border:2px solid #999;
    cursor: not-allowed;
}
