import { createContext, useState, useContext } from 'react';
import chevronRight from '../../../images/chevron-right.svg';
import chevronDown from '../../../images/chevron-down.svg';

const AccordionContext = createContext();

export const Accordion = ({ children }) => {
    const [activeKey, setActiveKey] = useState(null);

    const setKey = newKey => {
        if (newKey === activeKey) setActiveKey(null);
        else setActiveKey(newKey);
    };

    return <AccordionContext.Provider value={{ activeKey, setKey }}>{children}</AccordionContext.Provider>;
};

const Content = ({ children, eventKey }) => {
    const { activeKey } = useContext(AccordionContext);
    if (activeKey !== eventKey) return null;
    return children;
};

const Toggle = ({ children, eventKey }) => {
    const { activeKey, setKey } = useContext(AccordionContext);
    const handleSetKey = () => setKey(eventKey);

    const toggleStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
        alignItems: 'center',
    };

    return (
        <div onClick={handleSetKey} style={toggleStyle}>
            {children}
            <div style={{ height: '30px', width: '30px' }}>
                {activeKey === eventKey ? (
                    <img src={chevronDown} style={{ marginTop: '7px' }} alt="arrow-icon" />
                ) : (
                    <img src={chevronRight} style={{ marginTop: '2px' }} alt="arrow-icon" />
                )}
            </div>
        </div>
    );
};

Accordion.Content = Content;
Accordion.Toggle = Toggle;
