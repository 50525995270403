import iconSuccess from '../../images/icon-checked.svg';
import { TitleDescription } from  '../common/title-description/title-description'
import { buildUrl } from '../../helpers/buildUrl';

export const UtilityConfigSuccess = ( { session } ) => {
    const redirectUrl = buildUrl( session.redirectUrl, session.customerId );

    return(
        <div className='status-container'>
            <div style={ { width: '100%',height: '100%', boxSizing: 'border-box'} }  className='content-wrapper'>
            <TitleDescription img={iconSuccess} title='Success' imgClassName='title-description-small-img '/>
        <div style={ { textAlign: 'center', color:'#252525'} }>
            <p style={ { margin: '0px 0px 10px 0px' } }>Enersponse has now been connected to your utility!</p>
            { redirectUrl
                        ? <>
                            <p>Redirected to your dashboard.</p>
                            <div className='flex-center'>
                        <a className='redirect-btn' href={redirectUrl} style={ { marginTop: '30px' } }>Dashboard</a>
                    </div> 
                        </> 
                : <p> Now you can close this window.</p> }
        </div>
    </div>
</div> )};
