.utility-form-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.utility-logo img {
    width: 144px;
    height: 144px;
}

.form {
    max-width: 600px;
}

.form-row {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
}

.form-row-label {
    font-size: 24px;
    letter-spacing: 0.75px;
    text-align: center;
    color: #505050;
    margin-bottom: 10px;
}
.helper {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
}

.helper img {
    width: 24px;
    height: 24px;
}

.helper p {
    margin-left: 5px;
    text-decoration: underline;
    color: #4a7088;
}
.error-msg {
    border-radius: 6px;
    background-color: rgba(255, 59, 48, 0.1);
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #ff453a;
    padding: 8px 15px;
    text-align: left;
    font-size: 16px;
    margin-top: 8px;
}
