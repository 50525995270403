import { useEffect, useState } from 'react';
import iconProvider from '../../images/icon-provider.svg';
import { TitleDescription } from '../common/title-description/title-description';
import chevronRight from '../../images/chevron-right.svg';
import * as styles from './utility-list.module.css';

export const UtilityList = ({ zip, callback }) => {
    const [utilityList, setUtilityList] = useState([]);
    const [ filteredUtilityList, setFilteredUtilityList ] = useState( [] );
    const [ isREP, setIsREP ] = useState( false );

    useEffect(() => {
        (async () => {
            const utilityListData = await fetchUtilityList(zip);

            utilityListData.push({
                name: "I don't see my utility",
                code: 'not-found',
                energyServices: [],
                config: {
                    type: 'not-found',
                },
            });

            setUtilityList(utilityListData);
            setFilteredUtilityList( utilityListData );
            setIsREP( utilityListData.some( utility => utility.config.type === 'smartmeter' ) );
        })();
    }, [zip]);

    const handleSelectUtility = utility => () => callback({ utility: utility });

    const renderUtilityItem = utility => {
        return (
            <div className={`${styles['utility-list-item']}`} key={utility.code} onClick={handleSelectUtility(utility)}>
                <div className={styles['content']}>
                    {utility.logoUrl ? <img src={utility.logoUrl} alt={utility.name} className={styles['utility-logo']} /> : ''}
                    <p>{utility.name}</p>
                </div>
                <img src={chevronRight} alt="arrow-right" />
            </div>
        );
    };

    const onFilter = e => {
        const keyword = e.target.value;

        if (keyword !== '') {
            const results = utilityList.filter(item => {
                return item.name.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setFilteredUtilityList(results);
        } else {
            setFilteredUtilityList(utilityList);
        }
    };

    const title = isREP ? "Select your Retail Energy Provider (REP)" : "Select your Utility Provider"

    return (
        <>
            <div className="content-wrapper" style={{ paddingBottom: 0 }}>
                <TitleDescription img={ iconProvider } title={ title} />
                {utilityList.length > 5 ? (
                    <input type="text" className={styles['utility-list-filter']} onChange={onFilter} placeholder="Type utility name to search" />
                ) : (
                    ''
                )}
                <div className={styles['utility-list']}>{filteredUtilityList.map(renderUtilityItem)}</div>
            </div>
        </>
    );
};

export const fetchUtilityList = async zip => {
    const params = new URLSearchParams({ zip });
    const response = await fetch(`/api/v1/utilities?${params}`, { method: 'get', headers: { 'Content-Type': 'application/json' } });
    const respBody = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(response.error.message);
};
