import * as styles from './title-description.module.css'

export const TitleDescription = ( { img, title,imgClassName='', children } ) => <div className={ `${styles[ 'title-description' ]} flex-center`}>
    <div> 
        <div className='flex-center'>
            <img src={ img } alt='icon-location' className={imgClassName}/>
        </div>
        <p className={ styles[ 'page-title' ] }>{ title }</p>
        {children}
    </div>
    
</div>