import { Input } from '../../common/input/input'
import { Button } from '../../common/button/button'
import { TitleDescription } from '../../common/title-description/title-description';
import { ErrorMsg } from '../../common/errorMsg/errorMsg';
import questionIcon from '../../../images/icon-question-small.svg'
import iconProvider from '../../../images/icon-provider.svg';
import * as styles from '../utility.module.css'
import { useEffect,useState } from 'react';

export const CaptureView = ( { utility, onSubmit, email='' } ) => {
    const [values, setValues] = useState({...(email && {email})});
    const [ errors, setErrors ] = useState( {} );

    useEffect( () => {
        if (utility && utility.code !== 'not-found' ) {
            setValues( { ...values, 'utilityName': utility.name } )
        }
    }, [utility] );

    const handleChange = e => {
        const name = e.target.name;
        setValues( { ...values, [ name ]: e.target.value } );
        if ( errors[ name ] ) {
            setErrors( { ...errors, [ name ]: '' } ); 
        }
    };

    const isFormValid = () => { 
        let formIsValid = true;
        let errors = {};
        if ( !values[ 'utilityName' ] ) {
            formIsValid = false;
            errors[ 'utilityName' ] = "Utility Name cannot be empty";
        };
        if ( !values[ 'san' ] ) {
            formIsValid = false;
            errors['san'] ="SAN cannot be empty";
        };
        if ( !values[ 'email' ] ) {
            formIsValid = false;
            errors['email'] ="Email cannot be empty";
        };
        setErrors( errors );
        return formIsValid;
    };
 
    const handleSubmit = e => {
        e.preventDefault();
        if ( isFormValid() ) {
            onSubmit( { capture: values } );
        }
    };

    return <div className={`${styles['utility-form-wrapper']} `}>
        <div className='content-wrapper'>
            <TitleDescription img={ utility?.logoUrl || iconProvider } title='Please fill the form below' imgClassName='title-description-small-img'>
            { ( !utility || utility?.code === 'not-found') &&  <div className={ styles[ 'helper' ] }>
                        <img src={ questionIcon } alt='question-icon' />
                        <p>How to find your utility name</p>
                    </div> }   
            </TitleDescription>
            <form onSubmit={ handleSubmit } className={ styles[ 'form' ] } id='capture-utility-form'>
            <div className={styles['form-row']}>
                
                <Input
                    value={values.utilityName||''}
                    placeholder='Utility Name'
                    name='utilityName'
                    onChange={ handleChange }
                    disabled={utility && utility?.name }
                    />
                    { errors.utilityName && <ErrorMsg msg={ errors.utilityName}/>}
            </div>
    <div className={styles['form-row']}>
                <Input placeholder='Service Account Number'
                    name='san'
                    value={values.san || ''}
                    onChange={ handleChange }
                    />
                    { errors.san && <ErrorMsg msg={ errors.san }/>}
    </div>
    <div className={styles['form-row']}>
                <Input
                    placeholder='Email'
                    name='email'
                    type='email'
                    value={values.email || ''}
                    onChange={ handleChange }
                    />
                    {errors.email && <ErrorMsg msg={errors.email }/>}
    </div>
            </form>
        </div>
        <div className='flex-center content-wrapper'>
            <Button
                label='Next'
                type='submit'
                form='capture-utility-form'
            />
        </div>
</div>
}