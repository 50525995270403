import { useState } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { Input } from '../../common/input/input';
import { Button } from '../../common/button/button';
import { TitleDescription } from '../../common/title-description/title-description';
import { ErrorMsg } from '../../common/errorMsg/errorMsg';
import questionIcon from '../../../images/icon-question-small.svg';
import * as styles from '../utility.module.css';

export const SmartmeterView = ({ utility, onSubmit, email = '' }) => {
    const [values, setValues] = useState({ ...(email && { email }) });
    const [errors, setErrors] = useState({});

    const handleChange = e => {
        const name = e.target.name;
        setValues({ ...values, [name]: e.target.value });
        if (errors[name]) {
            setErrors({ ...errors, [name]: '' });
        }
    };

    const isFormValid = () => {
        let formIsValid = true;
        let errors = {};

        if (!values['esid']) {
            formIsValid = false;
            errors['esid'] = 'ESID cannot be empty';
        }
        if (!values['meterId']) {
            formIsValid = false;
            errors['meterId'] = 'MeterId cannot be empty';
        }
        if (!values['email']) {
            formIsValid = false;
            errors['email'] = 'Email cannot be empty';
        }

        setErrors(errors);
        return formIsValid;
    };

    const handleSubmit = e => {
        e.preventDefault();
        if (isFormValid()) {
            onSubmit({ smartmeter: values });
        }
    };

    const esiidTooltipContent =
        'Your electric service identification number—commonly known as your ESI ID—is a unique 17-digit number used to identify the electric service delivery to your home. You can find your ESI ID number on your electric bill or look up your address to find out your ESI ID.';

    return (
        <div style={{ width: '100%' }}>
            <div className="content-wrapper">
                <TitleDescription img={utility.logoUrl} title="Please fill the form below" imgClassName="title-description-small-img">
                    <Tippy content={<span>{esiidTooltipContent}</span>}>
                        <div className={styles['helper']}>
                            <img src={questionIcon} alt="question-icon" />
                            <p>How to find your ESIID number</p>
                        </div>
                    </Tippy>
                </TitleDescription>
                <form onSubmit={handleSubmit} className={styles['form']} id="smartmeter-utility-form">
                    <div className={styles['form-row']}>
                        <Input placeholder="ESIID#" name="esid" type="tel" value={values.esid || ''} onChange={handleChange} isRequired={true} />
                        {errors.esid && <ErrorMsg msg={errors.esid} />}
                    </div>
                    <div className={styles['form-row']}>
                        <Input
                            placeholder="MeterId"
                            name="meterId"
                            type="tel"
                            value={values.meterId || ''}
                            onChange={handleChange}
                            isRequired={true}
                        />
                        {errors.meterId && <ErrorMsg msg={errors.meterId} />}
                    </div>
                    <div className={styles['form-row']}>
                        <Input placeholder="Email" name="email" type="email" value={values.email || ''} onChange={handleChange} isRequired={true} />
                        {errors.email && <ErrorMsg msg={errors.email} />}
                    </div>
                </form>
            </div>

            <div className="flex-center content-wrapper">
                <Button label="Next" type="submit" form="smartmeter-utility-form" />
            </div>
        </div>
    );
};
