import * as styles from './button.module.css';

export const Button = ( { onClick=undefined, label, disabled=false,btnType='primary', ...props } ) => {
    const calculatedClassName = disabled && styles[ 'button-disabled' ] || styles[ `button-${ btnType }` ];
    return <button
            { ...props }
            disabled={disabled}
            className={`${styles.button} ${calculatedClassName}`}
            onClick={ onClick }
            >{ label }</button>
}