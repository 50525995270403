const dev = {
    GOOGLE_API: 'AIzaSyDiK241lsUpm8ZhXZQLc1ejHJbQzpttnpY',
    GA_CODE: 'G-34L7JZKG9N',
};

const staging = {
    GOOGLE_API: 'AIzaSyDiK241lsUpm8ZhXZQLc1ejHJbQzpttnpY',
    GA_CODE: 'G-34L7JZKG9N',
};

const production = {
    GOOGLE_API: 'AIzaSyDiK241lsUpm8ZhXZQLc1ejHJbQzpttnpY',
    GA_CODE: 'G-NZJ47RYRS6',
};

let config = { ...dev };

// The NODE_ENV environment variable is automatically set by Parcel depending on the mode
// When running parcel build, NODE_ENV is set to production by default, otherwise it is set to development
switch (process.env.NODE_ENV) {
    case 'development':
        config = { ...dev };
        break;
    case 'staging':
        config = { ...staging };
        break;
    case 'production':
        config = { ...production };
        break;
    default:
        config = { ...dev };
}

export default config;
