export const GA_EVENT_NAMES = {
    changeView: 'change_view',
    integrationFail: 'integration_failed',
    unexpectedError: 'unexpected_error',
    integrationSuccess: 'integration_success'
};

export const VIEWS = {
    zip: 'zip',
    utilityList: 'utility-list',
    utility: 'utility',
    success: 'success',
    fail: 'fail',
    error: 'error',
};
