import ReactDOM from 'react-dom';
import { GA4React } from 'ga-4-react';
import config from './config';
import { App } from './App';
import './index.css';

const ga4react = new GA4React(config.GA_CODE, { debug_mode: true });

(async () => {
    try {
        await ga4react.initialize();
    } catch (err) {
        console.error(err);
    }

    const app = document.getElementById('app');
    ReactDOM.render(
        <App />, app,
    );
})();
