import iconCancel from '../../images/icon-cancel.svg';
import { TitleDescription } from  '../common/title-description/title-description'
import { buildUrl } from '../../helpers/buildUrl';

export const UtilityConfigFail = ({ session }) => {
    const error = session?.error || 'Configuration was not successful. Please try again';
    const redirectUrl = buildUrl(session.redirectUrl, session.customerId, error);
    return (
        <div className="status-container">
            <div style={ { width: '100%',height: '100%', boxSizing: 'border-box'} } className='content-wrapper'>
            <TitleDescription img={iconCancel} title={error} imgClassName='title-description-small-img '/>
                <div className="flex-center">
                    {redirectUrl ? (
                        <a className="redirect-btn" href={redirectUrl} style={{ marginTop: '30px' }}>
                            Complete
                        </a>
                    ) : (
                        <p style={{marginTop:0, textAlign:'center'}}> Now you can close this window.</p>
                    )}
                </div>
            </div>
        </div>
    );
};
