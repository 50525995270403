import iconCancel from '../../images/icon-cancel.svg';
import { TitleDescription } from  '../common/title-description/title-description'

export const Error = ( { message } ) => {
    return (
        <div className='status-container'> 
            <div style={ { width: '100%',height: '100%', boxSizing: 'border-box'} } className='content-wrapper'>
            <TitleDescription img={iconCancel} title={message || 'Unknown Error!'} imgClassName='title-description-small-img '/>
            </div>
        </div>
    );
};
