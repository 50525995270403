export const initSession = async params => {
    try {
        const response = await fetch(`/api/v1/session`, {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...(params.sign && { sign: params.sign }),
                ...(params.clientId && { clientId: params.clientId }),
                ...(params.customerId && { customerId: params.customerId }),
                ...(params.email && { email: params.email }),
                ...(params.utility && { utility: params.utility }),
                ...(params.zip && { zip: params.zip }),
            }),
        });
        const respBody = await response.json();

        if (response.ok) {
            return respBody;
        }

        throw new Error(response.error.message);
    } catch (err) {
        return {
            error: `Cannot initialize session!`,
        };
    }
};

export const updateSession = async params => {
    try {
        const response = await fetch(`api/v1/session`, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...(params.zip && { zip: params.zip }),
                ...(params.utility && { utility: params.utility }),
                ...(params.smartmeter && { smartmeter: params.smartmeter }),
                ...(params.greenbutton && { greenbutton: params.greenbutton }),
                ...(params.capture && { capture: params.capture }),
            }),
        });

        const respBody = await response.json();

        if (response.ok) {
            return respBody;
        }

        throw new Error(respBody?.error?.message || `Cannot update session!`);
    } catch (err) {
        return {
            success: false,
            error: err.message || `Cannot update session!`,
        };
    }
};

export const fetchSession = async () => {
    const response = await fetch(`/api/v1/session`, {
        method: 'get',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const respBody = await response.json();

    if (response.ok) {
        return respBody;
    }

    if (response.status === 404) {
        throw new GuestSessionError();
    } else {
        throw new Error(response.error.message);
    }
};

export class GuestSessionError extends Error {
    constructor(error) {
        super(error);
        this.name = 'guestSessionError';
    }
}
