.utility-list {
    overflow-y: auto;
    height: 75vh;
    min-height: 75vh;

    background: #fff;
    padding: 30px 15px 30px 0px;
    border-radius: 0 0 35px 35px;
}

.utility-list-filter {
    /* width: 100%;
    font-size: 16px; */

    background: transparent;
    border: none;
    border-bottom: 2px solid #b9b9b9;
    font-family: Roboto, sans-serif;
    font-size: 30px;
    font-weight: 300;
    outline: none;
    width: 100%;
    margin-bottom: 25px;
}

.utility-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
    cursor: pointer;
}

.utility-list-item:hover {
    background: #f8fbfb;
}

.utility-list-item .content {
    display: flex;
    align-items: center;
}

.utility-logo {
    width: 48px;
    height: 48px;
    margin-right: 20px;
}
