export const buildUrl = ( redirectUrl, customerId, error ) => {
    if ( !redirectUrl ) return null;
    const url = new URL( redirectUrl );
    const searchParams = url.searchParams;
    searchParams.append( 'customerId', customerId );
    if ( typeof error !== 'undefined' ) {
        searchParams.append( 'error', error );
    }

    url.search = searchParams.toString();
    return url.toString();
};